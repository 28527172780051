.FlowerPane{
    background: #EC7700;
    border: 2px solid rgba(236, 119, 0, 0.23);
    box-sizing: border-box;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    color: #fff;
    margin: 0 8px;
    padding: 8px;
    text-align: center;
}

.FlowerPane p, .FlowerPane h3{margin:0; margin-bottom: 8px}
.FlowerPane h3{letter-spacing: -0.24px;}

.FlowerOrange{
    width: 40%;
    height: 100px;
    margin-left: 10%;
    /* background-image: url(/public/flower-orange.png); */
    background-position: top left;
    background-size: 100%;
}
.FlowerPink{
    width: 50%;
    height: 80px;
    margin-right: 10%;
    margin-left: auto;
    /* background-image: url(/public/flower-orange.png); */
    background-position: bottom right;
    background-size: 100%;
}


.FlowerPane,
.FlowerOrange,
.FlowerPink {transition: opacity .5s}

